import { useTranslation } from 'react-i18next'
import NextLink from 'next/link'
import { Link } from 'settle-ui/components/Link/Link'
import { ERouterPage } from 'lib/navigation'
import { logEvent } from 'lib/tracking/segment'
import * as events from '../../LoginPage.events'

interface IForgotPasswordLinkProps {
  email: string
}

export const ForgotPasswordLink: Settle.FC<IForgotPasswordLinkProps> = ({ email }) => {
  const { t } = useTranslation()
  const handleClick = () => logEvent(events.FORGOT_PASSWORD_LINK_CLICK)
  const href = `${ERouterPage.forgotPassword}?email=${email}`

  return (
    <Link color="secondary" onClick={handleClick} href={href} component={NextLink}>
      {t('forgotPassword')}
    </Link>
  )
}
